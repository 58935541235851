<template>
  <div v-if="header">
    <div
      :class="[types, sizes]"
      class="relative flex justify-between items-center text-center border border-gray-300 rounded-xl overflow-hidden gap-4"
    >
      <NuxtPicture
        :class="[sizes]"
        class="object-cover w-auto grid place-content-center"
        :height="640"
        :width="640"
        :img-attrs="{ class: [sizes, 'object-cover'] }"
        :src="`/vaa-graphics/${election.FrontendUrlPrefix}/promo-L.png`"
      />
      <div class="z-10 flex flex-col justify-between py-15 gap-2">
        <h3
          class="m-auto text-center uppercase leading-none font-sans"
          :class="
            size === 'small'
              ? 'list-title-m leading-none'
              : 'list-title-xl leading-none'
          "
        >
          <div :class="headerTextClasses">
            {{ header }}
          </div>
          <div v-if="election" class="font-bold">
            {{ election.Name }}
          </div>
        </h3>

        <NuxtLink
          :to="{ name: 'election', params: { slug: cta } }"
          data-testid="ct-election-card-cta"
          @click="
            sendStats($t(ctaLabel), {
              name: 'election',
              params: { slug: cta },
            })
          "
        >
          <CommonButton
            size="large"
            :bg="buttonBG"
            :text="buttonText"
            :border="'border-none'"
          >
            {{ $t(ctaLabel) }}
          </CommonButton>
        </NuxtLink>
      </div>
      <NuxtPicture
        :class="[sizes]"
        class="object-cover w-auto grid place-content-center"
        :height="640"
        :width="640"
        :img-attrs="{ class: [sizes, 'object-cover'] }"
        :src="`/vaa-graphics/${election.FrontendUrlPrefix}/promo-R.png`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import type { Election } from '~/typesManual/vaa_api/election'
import type { Theme } from '~/typesManual/config/vaa'

const props = withDefaults(
  defineProps<{
    header: string
    cta: string
    ctaLabel: string
    size: 'large' | 'small'
    election: Election
    theme: Theme
  }>(),
  {
    theme: 'light',
  }
)

const types = computed(() => {
  switch (props.theme) {
    case 'dark':
      return 'bg-darkerblue text-white'
    case 'light':
      return 'bg-white text-darkerblue'
    case 'blue':
      return 'bg-[#123293] text-white'
  }
})

const sizes = computed(() => {
  switch (props.size) {
    case 'large':
      return 'h-320'
    case 'small':
      return 'h-240'
  }
})

const buttonBG = computed(() => {
  switch (props.theme) {
    case 'dark':
    case 'blue':
      return 'bg-white'
    case 'light':
      return 'bg-darkerblue'
  }
})

const buttonText = computed(() => {
  switch (props.theme) {
    case 'dark':
    case 'blue':
      return 'text-darkerblue'
    case 'light':
      return 'text-white'
  }
})

const headerTextClasses = computed(() => {
  switch (props.theme) {
    case 'dark':
      return 'font-light text-white text-opacity-50'
    case 'light':
      return 'font-light text-darkerblue text-opacity-50'
    case 'blue':
      return 'font-light text-[#F7CE46]'
  }
})

const gtm = useGtm()

function sendStats(label: string, redirectRoute?: RouteLocationRaw) {
  if (!gtm) return

  if (label === 'Tag testen' && props.election.valgomat) {
    gtm.trackEvent({
      event: `GA - Event - Start taking test ${props.election.valgomat.name}`,
      eventCategory: 'Click',
      eventAction: 'Call To Action',
      eventLabel: 'Start taking test',
    })
  }
  if (label === 'Tag testen' && props.election) {
    gtm.trackEvent({
      event: `GA - Event - Start taking test ${props.election.FrontendUrlPrefix}`,
      eventCategory: 'Click',
      eventAction: 'Call To Action',
      eventLabel: 'Start taking test',
    })
  }

  if (redirectRoute) {
    navigateTo(redirectRoute)
  }
}
</script>
